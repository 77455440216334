<template>
    <v-container fluid tag="section">
        <v-row justify="center">
            <!-- <v-col class="d-flex" cols="12" sm="4">
                <v-select color="blue-grey lighten-4" solo item-color="blue-grey lighten-4" v-model="tipoNomina" :menu-props="{ top: true, offsetY: false }" placeholder="Seleccione tipo de nómina"
                    :items="tiposNomina" item-text="name" item-value="tipo" label="Tipo nómina" @change="loadYears">
                </v-select>
            </v-col> -->
            <v-col v-if="showYears" class="d-flex" cols="12" sm="4">
                <v-select color="blue-grey lighten-4" solo item-color="blue-grey lighten-4" :menu-props="{ top: true, offsetY: false }" v-model="anio" :items="years" item-text="year"
                    item-value="year" label="Año" @change="loadNumeroPlaza">
                </v-select>
            </v-col>
            <v-col v-if="anio != null && dataPlazas" class="d-flex" cols="12" sm="4">
                <v-select color="blue-grey lighten-4" solo item-color="blue-grey lighten-4" :menu-props="{ top: true, offsetY: false }" v-model="no_plaza" :items="dataPlazas" item-text="no_plaza"
                    item-value="id_empleado" label="No plaza" @change="loadDataNominas">
                </v-select>
            </v-col>
            <v-col cols="12" sm="12" style="padding:0px;margin:0px">
                <v-divider  />
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="showAlert" cols="12" sm="12">
                <v-alert align="center" dense min-height="25" type="warning" style="padding:0px">
                    <strong>No hay nóminas timbradas</strong>
                </v-alert>
            </v-col>
            <v-col v-else-if="showTable" cols="12" sm="12" class="bg-white border rounded shadow">
                <v-client-table :data="data" :columns="columns" :options="options" ref="table" style="text-align:center">
                    <template slot="primeraQuincena" slot-scope="nomina">
                        <template v-if="nomina.row.primeraQuincena">

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" color="#e70c0e" small @click="imprimirPDF(nomina.row,1,1)">
                                            <v-icon>fas fa-file-pdf</v-icon>PDF
                                        </v-btn>
                                    </template>

                                    <span>Descargar PDF</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" color="#f57304" small @click="imprimirXML(nomina.row,1,1)">
                                            <v-icon>fas fa-file-excel</v-icon>XML
                                        </v-btn>
                                    </template>
                                    <span>Descargar XML</span>
                                </v-tooltip>
                        </template>
                        <template v-else>
                            <strong>NO HAY CFDI PARA ESTA FECHA</strong>
                        </template>
                    </template>
                    <template slot="segundaQuincena" slot-scope="nomina">
                        <template v-if="nomina.row.segundaQuincena">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" small color="#e70c0e" @click="imprimirPDF(nomina.row,1,2)">
                                        <v-icon>fas fa-file-pdf</v-icon>PDF
                                    </v-btn>
                                </template>
                                <span>Descargar PDF</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" color="#f57304" small @click="imprimirXML(nomina.row,1,2)">
                                        <v-icon>fas fa-file-excel</v-icon>XML
                                    </v-btn>
                                </template>
                                <span>Descargar XML</span>
                            </v-tooltip>
                        </template>
                        <template v-else>
                            <v-row justify="center">
                                <strong >NO HAY CFDI PARA ESTA FECHA</strong>
                            </v-row>

                        </template>
                    </template>

                    <template slot="primeraQuincenaParaescolar" slot-scope="nomina">
                        <template v-if="nomina.row.primeraQuincenaParaescolar">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" color="#e70c0e" small @click="imprimirPDF(nomina.row,3,1)">
                                            <v-icon>fas fa-file-pdf</v-icon>PDF
                                        </v-btn>
                                    </template>

                                    <span>Descargar PDF</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" color="#f57304" small @click="imprimirXML(nomina.row,3,1)">
                                            <v-icon>fas fa-file-excel</v-icon>XML
                                        </v-btn>
                                    </template>
                                    <span>Descargar XML</span>
                                </v-tooltip>
                        </template>
                        <template v-else>
                            <strong>NO HAY CFDI PARA ESTA FECHA</strong>
                        </template>
                    </template>
                    <template slot="segundaQuincenaParaescolar" slot-scope="nomina">
                        <template v-if="nomina.row.segundaQuincenaParaescolar">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" small color="#e70c0e" @click="imprimirPDF(nomina.row,3,2)">
                                        <v-icon>fas fa-file-pdf</v-icon>PDF
                                    </v-btn>
                                </template>
                                <span>Descargar PDF</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" color="#f57304" small @click="imprimirXML(nomina.row,3,2)">
                                        <v-icon>fas fa-file-excel</v-icon>XML
                                    </v-btn>
                                </template>
                                <span>Descargar XML</span>
                            </v-tooltip>
                        </template>
                        <template v-else>
                            <v-row justify="center">
                                <strong >NO HAY CFDI PARA ESTA FECHA</strong>
                            </v-row>
                        </template>
                    </template>

                    <template v-slot:extraordinaria="{row,index}" >
                        <template v-if="row.extraordinaria">
                            <v-select color="blue-grey lighten-4" solo item-color="blue-grey lighten-4" :menu-props="{ top: true, offsetY: false }" @change="cambiarSelect($event,index)"  :items="extraordinariasItems(row.extraordinaria)" item-text="year"
                              item-value="year" label="Nómina extraordinaria" >
                            </v-select>
                            <v-tooltip bottom v-if="row.selectedExtraordinaria !== null">
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" small color="#e70c0e" @click="imprimirPDF(row,2)">
                                        <v-icon>fas fa-file-pdf</v-icon>PDF
                                    </v-btn>
                                </template>
                                <span>Descargar PDF</span>
                            </v-tooltip>

                            <v-tooltip bottom v-if="row.selectedExtraordinaria !== null">
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" color="#f57304" small @click="imprimirXML(row,2)">
                                        <v-icon>fas fa-file-excel</v-icon>XML
                                    </v-btn>
                                </template>
                                <span>Descargar XML</span>
                            </v-tooltip>
                        </template>
                        <template v-else>
                            <v-row justify="center">
                                <strong >NO HAY CFDI PARA ESTA FECHA</strong>
                            </v-row>

                        </template>
                    </template>
                </v-client-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapState } from 'vuex'
export default {
    data:function(){
        return {
            //banderas para mostrar lo diferentes componentes
            showYears:false,
            showAlert:false,
            showTable:false,
            columns: ["mes","primeraQuincena","segundaQuincena","primeraQuincenaParaescolar","segundaQuincenaParaescolar","extraordinaria"],
            options: {
                headings: {
                    mes: 'Mes',
                    primeraQuincena: "1ra. quincena ordinaria",
                    segundaQuincena: "2da. quincena ordinaria",
                    primeraQuincenaParaescolar: "1ra. quincena paraescolar",
                    segundaQuincenaParaescolar: "2da. quincena paraescolar",
                    extraordinaria: "Extraordinarias",
                },
                sortable: [],
                texts: {
                    count:"",
                    filter:"Buscar:",
                    filterPlaceholder: 'Buscar...',
                    limit:"Resultados:",
                    recordsDisplay: 'Resultados',
                    page:"Página:",
                    noResults:"No hay resultados",
                    filterBy: 'Buscar...',
                    loading:'Cargando...',
                    defaultOption: 'Seleccionar {column}'
                },
                filterable: false,
                filterByColumn: false,
                resizableColumns: false,
                sendEmptyFilters :false,
                childRowTogglerFirst: true,
                showChildRowToggler: true,
                perPage: 5,
                perPageValues: [5, 10, 15, 20],

                templates:{
                }
            },

            anio:null,
            years:[],
            dataNominas:[],
            data:[],
            currentYear:null,
            dataPlazas:[],
            no_plaza:null
        }
    },
    computed:{
        ...mapState(["user","token","baseUrl"]),
    },
    async mounted(){
        this.$NProgress.done();
        this.currentYear = new Date().getFullYear()

        await this.loadYears()


    },
    methods: {
        async loadYears(){
            this.years = [];
            this.dataNominas = [];
            this.data = []
            this.anio = null;
            this.showYears = false;
            this.showAlert = false;
            this.showTable = false;
            try{
                let form = new FormData();
                // form.append("tipo_nomina",this.tipoNomina);
                form.append("id_empleado",this.user.rfc);
                let response = await this.$http.post("get-years",form);
                if(response.data.response){
                    this.years = response.data.years;

                    this.years.forEach((el)=>{
                      if(el == this.currentYear){
                        this.anio = `${this.currentYear}`
                        this.loadNumeroPlaza();
                      }
                    })
                    this.showYears = true;
                } else {
                    this.years = [];
                    this.showAlert = true;
                }

            }catch(error){
                this.years = [];
            }
        },
        async loadNumeroPlaza(){
            this.dataPlazas = []
             try{
                // if(this.tipoNomina != 2){
                let form = new FormData();
                form.append("rfc",this.user.rfc);
                form.append("anio",this.anio);
                let response = await this.$http.post("get-datos-plazas-empleados",form);
                if(response.data.response){
                this.dataPlazas = response.data.years;
                }else {
                }

            }catch(error){
                this.dataPlazas = [];
                this.data = [];

                this.showTable = true;
            }
        },
        async loadDataNominas(){
            this.showTable = false;
            this.showAlert = false;
            this.dataNominas = []
            this.data = []
             try{
                // if(this.tipoNomina != 2){
                  let form = new FormData();
                  form.append("id_empleado",this.no_plaza);
                  form.append("anio",this.anio);
                  let response = await this.$http.post("get-datos-nomina-ord-comp",form);
                  if(response.data.response){
                      this.dataNominas = response.data.data;
                      for (const property in this.dataNominas) {
                        this.dataNominas[property].selectedExtraordinaria = null;
                        this.data.push(this.dataNominas[property]);
                      }
                      console.log(this.dataNominas);

                      this.showTable = true;
                  }else {
                      this.showAlert = true;
                  }
                // }else {
                //     this.loadMeses();
                // }

            }catch(error){
                this.dataNominas = [];
                this.data = [];

                this.showTable = true;
            }
        },
        imprimirPDF(data,tipo,quincena = null){
            var extension = '.pdf';
            var file_path = "";
            if(tipo == 1 || tipo == 3){

                let selected = null
                if(tipo == 1){
                    if(quincena == 1){
                    selected = data.primeraQuincena
                    }else{
                    selected = data.segundaQuincena
                    }
                }else{
                    if(quincena == 1){
                    selected = data.primeraQuincenaParaescolar
                    }else{
                    selected = data.segundaQuincenaParaescolar
                    }
                }
                file_path = "assets/cfdi/timbrados/"+selected[0].file_path+"pdf/"+selected[0].file_name+extension;
                window.open(this.baseUrl + "download-files?token="+this.token+"&file_name=" + selected[0].file_name+"&file_path="+file_path+"&extension="+extension,"_blank");

            }else{
                let seleccionado = data.selectedExtraordinaria

                let selected = data.extraordinaria.filter((el)=> el.nombre == seleccionado)[0]

                file_path =  './assets/cfdi/timbradosNominaExtraordinaria/pdf/'+selected.file_name+extension;
                window.open(this.baseUrl + "download-files?token="+this.token+"&file_name=" + selected.file_name+"&file_path="+file_path+"&extension="+extension,"_blank");
            }
        },
        imprimirXML(data,tipo,quincena = null){
            var extension = '.xml';
            var file_path = "";
            if(tipo == 1 || tipo == 3){ //si es diferente de extraordinaria

                let selected = null
                if(tipo == 1){
                    if(quincena == 1){
                    selected = data.primeraQuincena
                    }else{
                    selected = data.segundaQuincena
                    }
                }else{
                    if(quincena == 1){
                    selected = data.primeraQuincenaParaescolar
                    }else{
                    selected = data.segundaQuincenaParaescolar
                    }
                }

                file_path = "assets/cfdi/timbrados/"+selected[0].file_path+"xml/"+selected[0].file_name+extension;
                window.open(this.baseUrl + "download-files?token="+this.token+"&file_name=" + selected[0].file_name+"&file_path="+file_path+"&extension="+extension, "_blank");
            }else{
                let seleccionado = data.selectedExtraordinaria

                let selected = data.extraordinaria.filter((el)=> el.nombre == seleccionado)[0]
                file_path =  './assets/cfdi/timbradosNominaExtraordinaria/xml/'+selected.file_name+extension;
                window.open(this.baseUrl + "download-files?token="+this.token+"&file_name=" + selected.file_name+"&file_path="+file_path+"&extension="+extension, "_blank");
            }
        },

        extraordinariasItems(extraordinarias){
          let filter = [];
          console.log(extraordinarias);
          extraordinarias.forEach((el)=>{
            filter.push(el.nombre)
          })
          return filter
        },
        cambiarSelect(event,index){
          this.data[index-1].selectedExtraordinaria = event

          this.data =[...this.data];
          this.$forceUpdate();
        },

    }

}
</script>
