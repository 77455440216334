var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[(_vm.showYears)?_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"color":"blue-grey lighten-4","solo":"","item-color":"blue-grey lighten-4","menu-props":{ top: true, offsetY: false },"items":_vm.years,"item-text":"year","item-value":"year","label":"Año"},on:{"change":_vm.loadNumeroPlaza},model:{value:(_vm.anio),callback:function ($$v) {_vm.anio=$$v},expression:"anio"}})],1):_vm._e(),(_vm.anio != null && _vm.dataPlazas)?_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"color":"blue-grey lighten-4","solo":"","item-color":"blue-grey lighten-4","menu-props":{ top: true, offsetY: false },"items":_vm.dataPlazas,"item-text":"no_plaza","item-value":"id_empleado","label":"No plaza"},on:{"change":_vm.loadDataNominas},model:{value:(_vm.no_plaza),callback:function ($$v) {_vm.no_plaza=$$v},expression:"no_plaza"}})],1):_vm._e(),_c('v-col',{staticStyle:{"padding":"0px","margin":"0px"},attrs:{"cols":"12","sm":"12"}},[_c('v-divider')],1)],1),_c('v-row',[(_vm.showAlert)?_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-alert',{staticStyle:{"padding":"0px"},attrs:{"align":"center","dense":"","min-height":"25","type":"warning"}},[_c('strong',[_vm._v("No hay nóminas timbradas")])])],1):(_vm.showTable)?_c('v-col',{staticClass:"bg-white border rounded shadow",attrs:{"cols":"12","sm":"12"}},[_c('v-client-table',{ref:"table",staticStyle:{"text-align":"center"},attrs:{"data":_vm.data,"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"primeraQuincena",fn:function(nomina){return [(nomina.row.primeraQuincena)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"#e70c0e","small":""},on:{"click":function($event){return _vm.imprimirPDF(nomina.row,1,1)}}},on),[_c('v-icon',[_vm._v("fas fa-file-pdf")]),_vm._v("PDF ")],1)]}}],null,true)},[_c('span',[_vm._v("Descargar PDF")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"#f57304","small":""},on:{"click":function($event){return _vm.imprimirXML(nomina.row,1,1)}}},on),[_c('v-icon',[_vm._v("fas fa-file-excel")]),_vm._v("XML ")],1)]}}],null,true)},[_c('span',[_vm._v("Descargar XML")])])]:[_c('strong',[_vm._v("NO HAY CFDI PARA ESTA FECHA")])]]}},{key:"segundaQuincena",fn:function(nomina){return [(nomina.row.segundaQuincena)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"#e70c0e"},on:{"click":function($event){return _vm.imprimirPDF(nomina.row,1,2)}}},on),[_c('v-icon',[_vm._v("fas fa-file-pdf")]),_vm._v("PDF ")],1)]}}],null,true)},[_c('span',[_vm._v("Descargar PDF")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"#f57304","small":""},on:{"click":function($event){return _vm.imprimirXML(nomina.row,1,2)}}},on),[_c('v-icon',[_vm._v("fas fa-file-excel")]),_vm._v("XML ")],1)]}}],null,true)},[_c('span',[_vm._v("Descargar XML")])])]:[_c('v-row',{attrs:{"justify":"center"}},[_c('strong',[_vm._v("NO HAY CFDI PARA ESTA FECHA")])])]]}},{key:"primeraQuincenaParaescolar",fn:function(nomina){return [(nomina.row.primeraQuincenaParaescolar)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"#e70c0e","small":""},on:{"click":function($event){return _vm.imprimirPDF(nomina.row,3,1)}}},on),[_c('v-icon',[_vm._v("fas fa-file-pdf")]),_vm._v("PDF ")],1)]}}],null,true)},[_c('span',[_vm._v("Descargar PDF")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"#f57304","small":""},on:{"click":function($event){return _vm.imprimirXML(nomina.row,3,1)}}},on),[_c('v-icon',[_vm._v("fas fa-file-excel")]),_vm._v("XML ")],1)]}}],null,true)},[_c('span',[_vm._v("Descargar XML")])])]:[_c('strong',[_vm._v("NO HAY CFDI PARA ESTA FECHA")])]]}},{key:"segundaQuincenaParaescolar",fn:function(nomina){return [(nomina.row.segundaQuincenaParaescolar)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"#e70c0e"},on:{"click":function($event){return _vm.imprimirPDF(nomina.row,3,2)}}},on),[_c('v-icon',[_vm._v("fas fa-file-pdf")]),_vm._v("PDF ")],1)]}}],null,true)},[_c('span',[_vm._v("Descargar PDF")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"#f57304","small":""},on:{"click":function($event){return _vm.imprimirXML(nomina.row,3,2)}}},on),[_c('v-icon',[_vm._v("fas fa-file-excel")]),_vm._v("XML ")],1)]}}],null,true)},[_c('span',[_vm._v("Descargar XML")])])]:[_c('v-row',{attrs:{"justify":"center"}},[_c('strong',[_vm._v("NO HAY CFDI PARA ESTA FECHA")])])]]}},{key:"extraordinaria",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(row.extraordinaria)?[_c('v-select',{attrs:{"color":"blue-grey lighten-4","solo":"","item-color":"blue-grey lighten-4","menu-props":{ top: true, offsetY: false },"items":_vm.extraordinariasItems(row.extraordinaria),"item-text":"year","item-value":"year","label":"Nómina extraordinaria"},on:{"change":function($event){return _vm.cambiarSelect($event,index)}}}),(row.selectedExtraordinaria !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"#e70c0e"},on:{"click":function($event){return _vm.imprimirPDF(row,2)}}},on),[_c('v-icon',[_vm._v("fas fa-file-pdf")]),_vm._v("PDF ")],1)]}}],null,true)},[_c('span',[_vm._v("Descargar PDF")])]):_vm._e(),(row.selectedExtraordinaria !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"#f57304","small":""},on:{"click":function($event){return _vm.imprimirXML(row,2)}}},on),[_c('v-icon',[_vm._v("fas fa-file-excel")]),_vm._v("XML ")],1)]}}],null,true)},[_c('span',[_vm._v("Descargar XML")])]):_vm._e()]:[_c('v-row',{attrs:{"justify":"center"}},[_c('strong',[_vm._v("NO HAY CFDI PARA ESTA FECHA")])])]]}}])})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }